import { useMemo } from 'react'
import { getAddress } from '@ethersproject/address'
import { hooks } from '@/connectors/metamask'
import PRESALES from '@/constants/presales'

const { useAccount } = hooks

export const useIsWhitelist = (presale: string) => {
  const account = useAccount()

  return useMemo(() => {
    const whitelist = PRESALES[presale]
    if (!account) return false
    return whitelist?.some(item => getAddress(item) === getAddress(account))
  }, [presale, account])
}

export const useIsPublicSale = (presale: string) => {
  return useMemo(() => !PRESALES[presale], [presale])
}
